export default [
  {
    key: 'name',
    label: 'field.name',
    rules: 'required',
    type: 'text',
  },
  {
    key: 'phone',
    label: 'field.phoneNumber',
    rules: 'required',
    type: 'tel',
  },
  {
    key: 'maxBetTime',
    label: 'field.maxBetTime',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetGame',
    label: 'field.maxBetGame',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxWinDay',
    label: 'field.maxWinDay',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'loginTimeout',
    label: 'field.loginTimeout',
    rules: 'required|integer',
    type: 'text',
  },
  {
    label: 'breadcrumb.accountInfomration',
    icon: 'LockIcon',
    type: 'divider',
  },
  {
    key: 'parentId',
    label: 'field.underAccount',
    rules: 'required',
    type: 'asynSingleSelection',
    repository: 'agent',
    selectionKey: 'id',
    selectionLabel: 'username',
  },
  {
    key: 'username',
    label: 'field.username',
    rules: 'required|alpha_num',
    type: 'text',
  },
  {
    key: 'password',
    name: 'new-password',
    label: 'field.password',
    rules: 'required|min:6',
    type: 'password',
    hideInView: true,
  },
  {
    key: 'retypePassword',
    label: 'field.retypePassword',
    rules: 'required|confirmed:password',
    type: 'password',
    hideInView: true,
  },
  {
    key: 'isEnable',
    label: 'status.active',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
  {
    key: 'isReadonly',
    label: 'status.readonly',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
},
]
